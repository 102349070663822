import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import './bookASession.css';
import { useSearchParams } from "react-router-dom";

function onToggleSelectedProduct(product) {
  product.selected = !product.selected;
  product.count = product.selected ? 1 : 0;
}

function getCardFromProduct(product, onToggleEvent, onInputChange) {
  return (
    <Card key={product.id} className="shopping-card">
      <Card.Body>
        <Card.Title>{product.name}</Card.Title>
        <Card.Text className="shopping-card-body">
          {product.description} <br/><br/>${product.price.toFixed(2)} Each
        </Card.Text>
      </Card.Body>
      <Card.Footer className="shopping-card-footer">
        <Button hidden={product.selected === true} variant="primary" onClick={onToggleEvent}>Select</Button>
        <div className="selected-div" hidden={product.selected === false} >
          <Button variant="secondary"  onClick={onToggleEvent}>Deselect</Button><br/>
          <label>Quantity:</label><input type="number" value={product.count} onChange={onInputChange}></input>
        </div>
      </Card.Footer>
    </Card>
  );
}

async function checkout(baseUrl, products, childName) {
  const request = {
    items: products.filter(product => product.selected),
    child_name: childName
  };
  const res = await fetch(`${baseUrl}/v1/checkout-session`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  });
  const body = await res.json();
  window.location.href = body.url;
}

export function BookASession(props) {
    const [products, setProducts] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [name, setName] = useState("");

    useEffect(() => {
      async function fetchData() {
        const query = props.prek === "true" ? "?prek=true" : "";
        const res = await fetch(`${props.baseUrl}/v1/products${query }`);
        const body = await res.json();
        setProducts(body.map(p => ({ ...p, selected: false, count: 0, price: p.priceDetails.unit_amount / 100.0 })));
        setLoaded(true);
      }
      fetchData();
    }, []);

    function onSelectionChange(product) {
      onToggleSelectedProduct(product);
      setProducts([...products]);
    }

    function onInputChange(product, e) {
      if (e.target.value <= 0) {
        e.target.value = 1;
      }
      product.count = e.target.value;
      setProducts([...products]);
    }

    return(
      <Container className="session-body">
          <Spinner hidden={loaded === true} animation="grow" />
          <Row hidden={loaded === false} className="shopping-header">
            {props.prek === "true" ? <h2>Pre-K Packages</h2> : <h2>K-3 Packages</h2>}
          </Row>
          <Row hidden={loaded === false} className="shopping-list">
            { 
              products.map(product => getCardFromProduct(product, () => onSelectionChange(product), (evt) => onInputChange(product, evt))) 
            }
          </Row>
          <Row hidden={loaded === false}>
            <Col md="4" style={{ margin: "auto"}}>
              <label htmlFor="childName" style={{ padding: "0.4rem" }}> Please enter your child's name</label>
              <input id="childName" type="text" onChange={(evt) => setName(evt.target.value)} value={name}></input>
            </Col>
          </Row>
          <Row hidden={loaded === false}>
            <Col md="4" style={{ margin: "auto"}}>
              <p style={{ fontSize: "0.70rem" }}>Note: if you would like to order packages for multiple children, please submit multiple orders.</p>
            </Col>
          </Row>
          <Row hidden={loaded === false}>
            <Col md="12">
              <Button disabled={products.filter(s => s.selected === true).length === 0 || !name }  onClick={() => checkout(props.baseUrl, products, name)}>Proceed to checkout</Button>
            </Col>
          </Row>
      </Container>
    )
}

export function DoneBooking(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const baseUrl = props.baseUrl;
  const success = searchParams.get('success');

  useEffect(() => {
    async function fetchData() {
      const request = {
        session_id: searchParams.get('session_id')
      };
      const res = await fetch(`${baseUrl}/v1/checkout-complete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      });
    
      console.log(res);
    };
    fetchData();
  }, []);
  
  if (success === 'true') {
    return (
      <Container>
        <h2>Thank you for your order!</h2>
        <p>I will edit and provide your photos asap.  If you have any questions, please contact me and queenlb@miamioh.edu</p>
      </Container>
    );
  }
}