import { Col, Container, Row } from "react-bootstrap";
import './Home.css';

export function Home() {
    return(
        <>
            <Row className="main-header">
                <Col md="12">
                    <h1>Welcome to Mama B Photography</h1>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <hr className="horizontal-separator"/>
                    <Container>
                        <Row>
                            <Col md="6" sm="12" xs="12" className="main-left">
                                <h2>About Me</h2>
                                <p>Hello, and welcome to Mama B Photography.  My name is Leah Bell, and I am the face behind the camera.  I got started with Photography
                                    when my oldest was a baby and quickly fell in love with the hobby.  As my children grew, and I had more time,  I began taking photos for others, and now here we are!  
                                    I do family, couples, children, and senior picture sessions.  I do not do weddings or newborns at this time.  If you have any questions, feel free to reach out to me 
                                    at <a href="https://www.facebook.com/profile.php?id=100054788386734">my facebook page</a>.  
                                </p>
                            </Col>
                            <Col md="6" sm="12" xs="12" className="main-right">
                                <img src="./about-me.jpg" alt="Leah Bell" className="about-me-image"/>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </>
    )
}