import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavbarCollapse from 'react-bootstrap/NavbarCollapse';
import NavbarToggle from 'react-bootstrap/NavbarToggle';
import { NavDropdown } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import './NavBar.css';

function getCarousel(styles) {
  const items = [];
  for(let style of styles) {
    items.push((
      <Carousel.Item>
        <div className="carousel-image" style={style}></div>
        <Carousel.Caption>
          <h3>
            I am a slide
          </h3>
          <p>And I am a description</p>
        </Carousel.Caption>
      </Carousel.Item>));
  }

  return items;
}

export function MamabWrapper() {
    const style = {
      "backgroundImage": "url('./peyton-tree.jpg')",
      "height": "30vh",
      "width": "auto",
      "animation": "pan 30s linear infinite"
    };
    const styles = [];
    styles.push(style);
    return(<>
      <Navbar expand="lg" bg="primary" variant="light">
      <Container>
        <Navbar.Brand href="/">Mama B Photography</Navbar.Brand>
        <Nav className="me-auto">
          <NavbarCollapse>
            <NavDropdown title="Overfield Packages">
                <NavDropdown.Item href="/kindergarten-packages">K-3</NavDropdown.Item>
                <NavDropdown.Item href="/prek-packages">Pre-K</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/gallery">Gallery</Nav.Link>
          </NavbarCollapse>
          <NavbarToggle> <span className="navbar-toggler-icon"></span></NavbarToggle>
        </Nav>
      </Container>
    </Navbar>
      {/* <Carousel>
        {getCarousel(styles)}
      </Carousel> */}
    </>
    )
}