import { Calendar } from 'react-calendar';
import { useEffect, useState } from "react";
import 'react-calendar/dist/Calendar.css';
import './calendar.css';
import { TimeSelector } from './time-selector/timeSelector';

//const baseUrl = 'http://localhost:8000';
const baseUrl = 'https://zfm9d2f39f.execute-api.us-east-1.amazonaws.com/dev';

function formatDate(date) {
    console.log(date);
    return `${date.getFullYear()}${date.getMonth()}${date.getDate()}`;
}

function dateConverter(s) {
  const year = s.split('-')[0];
  const month = s.split('-')[1];
  const day = s.split('-')[2];
  const now = new Date(year, month-1, day, 0, 0, 0, 0);

  now.setHours(0, 0, 0, 0);

  return now;
}

export function validateNotEmpty(field, value) {
    if (!value || value === '') {
        throw Error(`${field} must not be empty`);
    }
}

export function MamabCalendar(props) {
    async function fetchData() {
        const res = await fetch(`${baseUrl}/v1/events/${props.event}`);
        const body = await res.json();
        const newAvailable = body.map(s => { return { timeslots: s.timeslots, date: dateConverter(s.date)}; });
        setAvailable(newAvailable);
    }
    const [date, setDate] = useState();
    const [availableTimes, setAvailableTimes] = useState([]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [available, setAvailable] = useState([]);

    useEffect(() => {
        fetchData();
        setDate(props.start);
    }, []);

    const onSelection = async (selectedTime) => {
        try {
            validateNotEmpty('date', date);
            validateNotEmpty('phone', phone);
            validateNotEmpty('name', name);
            validateNotEmpty('email', email);
            validateNotEmpty('time', selectedTime);
        } catch(err) {
            alert(err);
            return;
        }

        await fetch(`${baseUrl}/v1/appointment`, {
            method: 'POST',
            body: JSON.stringify({
                time: selectedTime,
                event: props.event,
                date,
                name,
                email,
                phone,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        alert('Your appointment has been booked.  Thank you!');
        fetchData();
    };


    useEffect(() => {
        async function loadAvailable() {
            console.log('Loading on date change.');

            if(!date || !available || available.length === 0) {
                setAvailableTimes([]);
            }
    
            const currentAvailable = available.filter(s => formatDate(s.date) === formatDate(date));
            if(currentAvailable.length > 0) {
                setAvailableTimes(currentAvailable[0].timeslots);
            } else {
                setAvailableTimes([]);
            }
        }
        loadAvailable();
    }, [date, available])

    return (
        <>
            <div className="row calendar-row">
                <div className='col-md-4'>
                </div>
                <div className='col-md-4 col-xs-12 calendar-container'>
                    <Calendar onChange={setDate} value={date}></Calendar>
                </div>
                <div className='col-md-4'>
                </div>
            </div>
            <div className="row name-row">
                <div className='col-md-2'>
                    <label>Name*: </label>
                </div>
                <div className='col-md-2'>
                <input type="text" onChange={(evt) => setName(evt.target.value)} value={name}></input>
                </div>
                <div className='col-md-8'>
                </div>
            </div>
            <div className="row name-row">
                <div className='col-md-2'>
                    <label>Phone*: </label>
                </div>
                <div className='col-md-2'>
                <input type="text" onChange={(evt) => setPhone(evt.target.value)} value={phone}></input>
                </div>
                <div className='col-md-8'>
                </div>
            </div>
            <div className='row name-row'>
                <div className='col-md-2'>
                    <label>Email*: </label>
                </div>
                <div className='col-md-2'>
                <input type="text" onChange={(evt) => setEmail(evt.target.value)} value={email}></input>
                </div>
                <div className='col-md-8'>
                </div>
            </div>
            <div className='row name-row'>
                <div className='col-md-3'></div>
                <div className='col-md-6'>
                    <TimeSelector availableTimes={availableTimes} onSave={onSelection}></TimeSelector>
                </div>
                <div className='col-md-3'></div>
            </div>
        </>
    )
}