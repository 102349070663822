import { useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './timeSelector.css';

function getButtons(times, onClick) {
    const items = times.map(s => <div key={s.time} className="col-3 button-container"><Button disabled={!s.available} onClick={() => onClick(s.time)} type="button" className="btn btn-primary timebutton" key={s.time}>{s.time}</Button></div>);
    return <>{items}</>
}

export function TimeSelector(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleSave = async () => {
        await props.onSave(selectedTime);
        handleClose();
    };
    const [selectedTime, setSelectedTime] = useState('');
    const handleOpen = (time) => { 
        setShow(true);
        setSelectedTime(time);
    };
    
    return (
        <>
            <div className='container'>
                <div className='row'>
                    {getButtons(props.availableTimes, handleOpen)}
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Appointment</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you would like to request an appointment?</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save Changes
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}