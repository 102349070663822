import logo from './logo.svg';
import './App.css';
import { MamabCalendar } from './calendar/calendar';
import { useEffect, useState } from 'react';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MamabWrapper } from './navigation/NavBar';
import { AppRouter } from './AppRouter';



function App() {
  
  return (
    <div className="App">      
      <MamabWrapper></MamabWrapper>
      <AppRouter></AppRouter>
    </div>
  );
}

export default App;
