import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';
import { BookASession, DoneBooking } from './bookASession/BookASession';
import { MamabCalendar } from './calendar/calendar';
import { Gallery } from './gallery/Gallery';
import { Home } from './home/Home';

// const baseUrl = 'http://localhost:8000';
const baseUrl = 'https://zfm9d2f39f.execute-api.us-east-1.amazonaws.com/dev';
export function AppRouter() {
    return(
        <BrowserRouter>
            <Routes>
                <Route path='/' element={
                    <Home></Home>
                }></Route>
                <Route path='/overfield' element={
                    <>
                        <h3>Overfield</h3>
                        <MamabCalendar event='overfield' start={new Date(2022, 10, 6, 0, 0, 0, 0)}></MamabCalendar>
                    </>
                }>
                </Route>
                <Route path="/kindergarten-packages" element={
                    <>
                        <BookASession baseUrl={baseUrl}></BookASession>
                    </>
                }></Route>
                <Route path="/prek-packages" element={
                    <>
                        <BookASession baseUrl={baseUrl} prek="true"></BookASession>
                    </>
                }></Route>
                <Route path="/packages/booked" element={
                    <>
                        <DoneBooking baseUrl={baseUrl}></DoneBooking>
                    </>
                }></Route>
                <Route path="/gallery/:session" element={
                    <>
                        <Gallery baseUrl={baseUrl}></Gallery>
                    </>
                }> </Route>
                <Route path="/gallery" element={
                    <>
                        <Gallery baseUrl={baseUrl}></Gallery>
                    </>
                }> </Route>
            </Routes>
        </BrowserRouter>
    )
}