import Button from "react-bootstrap/Button";
import { useEffect, useState } from "react";
import './Gallery.css'
import Masonry from 'masonry-layout';
import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useParams } from "react-router-dom";


function getSingleImage(picture, loadEvent, onClickEvent, fullLink) {
    return (
        <img key={picture.url} className="grid-item" alt={picture.url} src={picture.url} onLoad={() => loadEvent()} onClick={() => onClickEvent(fullLink)}></img>
    )
}

function getFileNameNoPath(fileNameWithPath) {
    const split = fileNameWithPath.split('/');
    return split[split.length - 1];
}

function getImagesForGallery(pictures, loadEvent, onClickEvent) {
    const images = [];
    for(const picture of pictures.previewLinks) {
        const previewFileName = getFileNameNoPath(picture.file);
        let fullLink = '';
        for(const fullPic of pictures.links) {
            const fullFileName = getFileNameNoPath(fullPic.file);
            if(previewFileName === fullFileName) {
                fullLink = fullPic.url;
                break;
            }
        }
        images.push(getSingleImage(picture, loadEvent, onClickEvent, fullLink));
    }

    return images;
}

function PreviewModal(props) {
    const picture = props.picture;
    return (
      <Modal
        show={props.show}
        onHide={props.onClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <img className="preview" src={picture} alt="preview"></img>
        </Modal.Body>
      </Modal>
    );
  }

export function Gallery(props) {
    const { session } = useParams();
    const [code, setCode] = useState(session ?? '');
    const [pictures, setPictures] = useState([]);
    const [zipFile, setZipFile] = useState({});
    const [zipWebFile, setZipWebFile] = useState({});
    const [imagesLoaded, setImagesLoaded] = useState(0);
    const [currentPicture, setCurrentPicture] = useState('');
    const [showPreview, setShowPreview] = useState(false);
    const [picError, setPicError] = useState('');


    const myRef = React.createRef();
    const handleClick = async () => {
        setPicError('');
        try {
            const list = await fetch(`${props.baseUrl}/v1/pictures/${code}`);
            const myZipFile = await fetch(`${props.baseUrl}/v1/pictures/${code}/zipfile`);
            const body = await list.json();
            const zipBody = await myZipFile.json();
            setImagesLoaded(0);
            setZipFile(zipBody.fullZipFile);
            setZipWebFile(zipBody.webZipFile);
            setPictures(body.pictures);
        } catch(err) {
            console.log(err);
            setPicError('We could not find any pictures for this code - make sure your session name is case sensitive!');
        }
    };

    const refreshMasonry = () => {
        if(!myRef?.current) {
            return;
        }
        const elem = myRef.current;
        const _ = new Masonry(elem, {
            // options
            itemSelector: '.grid-item',
            columnWidth: 20,
            percentPosition: true
        });
    }

    useEffect(() => {
        if(code) {
            handleClick();
        }
    }, [])

    useEffect(refreshMasonry, [pictures, imagesLoaded]);

    if (!pictures || pictures.length === 0) {
        return (
            <Container>
                <Row className="gallery-code-header">
                    <Col md="12">
                        <h1>Please enter your personalized session name to see your gallery!</h1>
                    </Col>
                </Row>
                <Row className="gallery-code-input">
                    <Col md="12">
                        <Row>
                            <Col md="12">
                                <input className="code" type='text' onChange={(evt) => setCode(evt.target.value)} value={code}></input>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <Button className="galleryButton" variant="primary" onClick={() => handleClick()}>View Gallery</Button>
                                <h3>{picError}</h3>
                            </Col>
                        </Row>
                    </Col>
                    
                </Row>
                <Row>
                    <Col md="12">
                    </Col>
                </Row>
            </Container>
        )
    }
    else {
        return (
            <>
                <Container>
                    <Row>
                        <Col md="12">
                            <a href={`${zipFile}`} download> Download Full Resolution Files </a>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <a href={`${zipWebFile}`} download> Download Web Resolution Files </a>
                        </Col>
                    </Row>
                </Container>
                <div className="grid" ref={myRef}>
                    {getImagesForGallery(pictures, () => {
                        setImagesLoaded(imagesLoaded + 1);
                    }, (img) => {
                        setShowPreview(true); 
                        setCurrentPicture(img)
                    })}
                </div>
                <PreviewModal picture={currentPicture} show={showPreview} onClose={() => setShowPreview(false)}></PreviewModal>
            </>
        )
    }
}